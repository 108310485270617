function Welcome() {
    
  return (
      <>
        <h4>Sejam Bem - Vindos</h4>
        <p>ASB - System Solution</p>
      </>
    );
  
}

export default Welcome;