import './App.css';
import Welcome from "./components/Welcome";

function App() {
  return (
    <div className="App">
        <header className="App-header">
        <img src="./logo.png" className="App-logo" alt="logo" />
       <Welcome />
      </header>
    </div>
  );
}

export default App;
